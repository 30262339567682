@import "../Common";

.paragraph {
	display: flex;
	justify-content: space-between;
	width: 100%;

	.paragraph-text {
		font-weight: 500;
	}
}

#quantity-container {
	display: flex;
	position: relative;
	gap: 15px;

	#quantity {
		background-color: rgba($Accent-Fg, 0.1);
		border: none;
		border-radius: 45px;
		display: flex;
		align-items: center;
		padding: 2px 4px;

		#quantity-input {
			font-weight: 500;
			width: 120px;

			@media screen and (min-width: $Width-Tablet) { width: 180px; }
			@media screen and (min-width: $Width-Desktop) { width: 200px; }

			&::-webkit-outer-spin-button,
			&::-webkit-inner-spin-button {
				-webkit-appearance: none;
				margin: 0;
			}

			-moz-appearance: textfield;
		}
	}
}