@import "src/Assets/Styles/Common";

#games-text-slider {
	display: flex;
	flex-direction: column-reverse;
	align-items: center;

	#games-slider-container,
	#games-slider,
	#games-slider-border,
	#games-slider-track {
		width: 350px;
		height: 184px;

		@media screen and (min-width: $Width-Tablet) {
			width: 550px;
			height: 309px;
		}

		@media screen and (min-width: $Width-Desktop) {
			width: 700px;
			height: 394px;
		}
	}

	.game {
		width: 100%;
		height: 100%;
	}

	#games-slider,
	#games-slider-border {
		position: absolute;
	}

	#games-slider-border {
		pointer-events: none;
	}

	#games-text-description {
		width: 350px;

		@media screen and (min-width: $Width-Desktop) { width: 500px; }
	}

	@media screen and (min-width: $Width-Desktop) {
		flex-direction: row;
		gap: 80px;
	}
}