@import "src/Assets/Styles/Common";

.input-email {
	color: black;
	padding: 10px 0;
	border-bottom: 1px solid rgba(black, 0.25);

	&::placeholder {
		color: rgba(black, 0.5);
	}
}