@import "src/Assets/Styles/Common";

#aquarian-nft-collection {
	display: flex;
	flex-direction: column;
	align-items: center;
	gap: 40px;

	#aquarian-nfts {
		display: grid;
		grid-template-columns: auto auto auto auto;
		gap: 10px;

		.aquarian-nft {
			width: 70px;

			@media screen and (min-width: $Width-Tablet) { width: 90px; }
			@media screen and (min-width: $Width-Desktop) { width: 120px; }
		}

		@media screen and (min-width: $Width-Tablet) { gap: 15px; }
		@media screen and (min-width: $Width-Desktop) { gap: 20px; }
	}

	#aquarian-nft-collection-description {
		width: 350px;

		@media screen and (min-width: $Width-Desktop) { width: 500px; }
	}

	#aquarian-nft-collection-buttons {
		display: flex;
		flex-direction: column;
		gap: 10px;
		padding: 20px;

		@media screen and (min-width: $Width-Desktop) { flex-direction: row; }
	}

	@media screen and (min-width: $Width-Desktop) {
		flex-direction: row;
		gap: 80px;
	}
}