@import "src/Assets/Styles/Common";

#team {
	display: flex;
	flex-direction: column;
	align-items: center;
	gap: 80px;

	.team-member {
		display: flex;
		flex-direction: column;
		align-items: center;
		gap: 10px;

		.team-member-image {
			width: 85px;

			@media screen and (min-width: $Width-Tablet) { width: 100px; }
			@media screen and (min-width: $Width-Desktop) { width: 115px; }
		}

		.team-member-name {
			font-weight: 600;
		}

		.team-member-role {
			color: #ffcc00;
			font-size: 10px;
			text-transform: uppercase;
			text-align: center;
			width: 110px;

			@media screen and (min-width: $Width-Tablet) {
				font-size: 12px;
				width: 120px;
			}
			@media screen and (min-width: $Width-Desktop) {
				font-size: 14px;
				width: 130px;
			}
		}

		.team-member-experience {
			font-size: 12px;
			white-space: pre-wrap;
			text-align: center;
			width: 300px;

			@media screen and (min-width: $Width-Tablet) { font-size: 13px; }
			@media screen and (min-width: $Width-Desktop) { font-size: 14px; }
		}
	}

	#advisor-members {
		display: grid;
		grid-template-columns: auto;
		gap: 40px;

		@media screen and (min-width: $Width-Desktop) { grid-template-columns: auto auto auto;}
	}

	#team-members {
		display: grid;
		grid-template-columns: auto auto auto;
		gap: 10px;

		@media screen and (min-width: $Width-Tablet) { gap: 30px; }
		@media screen and (min-width: $Width-Desktop) {
			gap: 50px;
			grid-template-columns: auto auto auto auto auto auto auto;
		}
	}
}

