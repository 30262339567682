@import "src/Assets/Styles/Common";

$Accent-Default: #990bd0;
$Accent-Light: #ba5be1;

$Accent-Complete: #00ff8c;

$Container-Gap: 30px;
$Line-Width: 2px;
$YearLine-Width: 60px;
$YearPoint-Radius: 10px;
$QuarterPoint-Radius: 5px;

#roadmap-section {
	background-color: $Accent-Bg;

	#roadmap {
		display: flex;
		gap: $Container-Gap;
		transform: translateX(40px);

		#roadmap-line {
			background-image: linear-gradient(0deg, transparent 0%, $Accent-Default 4%, $Accent-Default 96%, transparent 100%);
			width: $Line-Width;
		}

		#roadmap-data {
			display: flex;
			flex-direction: column;
			padding: 80px 0;

			.roadmap-year-title {
				font-weight: 600;
				font-size: 16px;
				text-shadow: 2px 2px $Accent-Default;
				transform: translate(-100px, -50%);
				position: absolute;

				@media screen and (min-width: $Width-Tablet) {
					font-size: 18px;
					transform: translate(-110px, -50%);
				}

				@media screen and (min-width: $Width-Desktop) {
					font-size: 22px;
					transform: translate(-120px, -50%);
				}
			}

			.roadmap-year-line {
				background-image: linear-gradient(90deg, rgba($Accent-Default, 0.8) 0%, rgba($Accent-Default, 0.8) 50%, transparent 100%);
				width: $YearLine-Width;
				height: 2px;
				transform: translate(-($Container-Gap), -50%);
				position: absolute;
			}

			.roadmap-year-point {
				background-color: $Accent-Default;
				border: 2px solid $Accent-Light;
				border-radius: 50%;
				box-shadow: 0 0 12px 2px rgba($Accent-Default, 0.8);
				width: $YearPoint-Radius * 2;
				height: $YearPoint-Radius * 2;
				transform: translate(-($Container-Gap + $YearPoint-Radius + ($Line-Width / 2)), -50%);
				position: absolute;

				.roadmap-year-point-center {
					background-color: white;
					border-radius: 50%;
					box-shadow: 0 0 12px 2px rgba(white, 0.8);
					width: 5px;
					height: 5px;
					top: 50%;
					left: 50%;
					transform: translate(-50%, -50%);
					position: absolute;
				}
			}

			.roadmap-quarter {
				display: flex;
				flex-direction: column;
				gap: 15px;
				padding: 40px 0;
				position: relative;

				.roadmap-quarter-title {
					color: #cbcbcb;
					top: 50%;
					transform: translate(-80px, -50%);
					position: absolute;
				}

				.roadmap-milestone {
					display: flex;
					align-items: center;
					gap: 10px;

					.roadmap-milestone-status {
						border-radius: 50%;
						width: 7.5px;
						height: 7.5px;
					}

					.roadmap-milestone-complete {
						box-shadow: 0 0 8px 2px rgba($Accent-Complete, 0.8);
						background-color: $Accent-Complete;
					}

					.roadmap-milestone-todo {
						background-color: #939393;
					}

					.roadmap-milestone-text {
						font-weight: 300;
						width: 200px;

						@media screen and (min-width: $Width-Tablet) { width: 400px; }
						@media screen and (min-width: $Width-Desktop) { width: 800px; }
					}
				}
			}

			.roadmap-quarter-point {
				background-color: $Accent-Default;
				border-radius: 50%;
				width: $QuarterPoint-Radius * 2;
				height: $QuarterPoint-Radius * 2;
				transform: translateX(-($Container-Gap + $QuarterPoint-Radius + ($Line-Width / 2)));

				.roadmap-quarter-point-line {
					background-image: linear-gradient(90deg, transparent 0%, rgba($Accent-Default, 0.8) 40%, rgba($Accent-Default, 0.8) 60%, transparent 100%);
					width: 60px;
					height: 2px;
					left: 50%;
					top: 50%;
					transform: translate(-50%, -50%);
					position: absolute;
				}
			}
		}
	}
}