@import "Common";

.preloader {
	background-color: black;
	display: flex;
	justify-content: center;
	align-items: center;
	width: 100vw;
	height: 100vh;
	position: fixed;
	z-index: $Layer-Preloader;

	#preloader-content {
		display: flex;
		flex-direction: column;
		align-items: center;
		gap: 20px;

		#preloader-logo-icons {
			width: 150px;
			height: 150px;
			position: relative;

			@media screen and (min-width: $Width-Tablet) {
				width: 200px;
				height: 200px;
			}

			@media screen and (min-width: $Width-Desktop) {
				width: 250px;
				height: 250px;
			}

			.preloader-logo-icon {
				width: 100%;
				height: 100%;
				position: absolute;
				transition: 0.05s;
			}

			.preloader-logo-icon-light {
				filter: grayscale(1);
				opacity: 0.1;
			}

			.preloader-logo-icon-glow {
				filter: blur(15px);
				opacity: 0;
			}

			.preloader-logo-icon-light-active {
				filter: grayscale(0);
				opacity: 1;
			}

			.preloader-logo-icon-glow-active {
				opacity: 1;
			}

			#preloader-text {
				font-weight: 500;
			}
		}
	}
}

.preloader-fading {
	animation-name: preloader-loaded-fading-anim;
	animation-duration: 1s;
}

@keyframes preloader-loaded-fading-anim {
	0% { opacity: 1; }
	100% { opacity: 0; }
}

.preloader-faded {
	display: none;
}