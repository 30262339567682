@import "../Common";

.countdown-timer {
	display: flex;
	gap: 10px;

	.countdown-timer-unit {
		display: flex;
		flex-direction: column;
		align-items: center;

		width: 60px;
		height: 80px;

		@media screen and (min-width: $Width-Tablet) {
			width: 70px;
			height: 90px;
		}

		@media screen and (min-width: $Width-Desktop) {
			width: 80px;
			height: 100px;
		}

		.countdown-timer-unit-part {
			display: flex;
			justify-content: center;
			align-items: center;

			.countdown-timer-unit-part-value {
				font-weight: 600;
			}
		}

		.countdown-timer-unit-text {
			background-color: rgba(white, 0.2);
			border-top-left-radius: 15px;
			border-top-right-radius: 15px;
			width: 100%;
			height: 55%;

			.countdown-timer-unit-text-value {
				font-size: 18px;

				@media screen and (min-width: $Width-Tablet) { font-size: 22px; }
				@media screen and (min-width: $Width-Desktop) { font-size: 26px; }
			}
		}

		.countdown-timer-unit-name {
			background-color: white;
			border-bottom-left-radius: 15px;
			border-bottom-right-radius: 15px;
			width: 100%;
			height: 45%;

			.countdown-timer-unit-name-value {
				color: $Accent-Bg;
			}
		}
	}
}