@import "src/Assets/Styles/Common";

.card {
	display: flex;
	align-items: flex-end;
	background-size: cover;
	background-position: center;
	height: 360px;
	margin: 10px;
	padding: 40px;

	@media screen and (min-width: $Width-Tablet) {
		height: 540px;
		margin: 20px;
		padding: 50px;
	}
	@media screen and (min-width: $Width-Desktop) {
		height: 960px;
		margin: 30px;
		padding: 60px;
	}
}

.aquarians-section-title {
	color: #f4e563;
	font-size: 24px;
	font-weight: 800;
	text-transform: uppercase;

	@media screen and (min-width: $Width-Tablet) { font-size: 28px; }
	@media screen and (min-width: $Width-Desktop) { font-size: 36px; }
}

.aquarians-section-description {
	width: 300px;

	@media screen and (min-width: $Width-Tablet) { width: 400px; }
	@media screen and (min-width: $Width-Desktop) { width: 500px; }
}
