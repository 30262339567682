@import "Common";

body {
	background-color: #1b0a26;
}

#message {
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	gap: 10px;
	width: 100vw;
	height: 100vh;

	#message-title {
		font-weight: 600;
		font-size: 54px;
	}

	#message-description {
		font-weight: 600;
		font-size: 20px;
	}

	#message-details {
		color: #cbcbcb;
	}
}