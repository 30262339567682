@import "Common";

#header {
	padding: 20px 40px;
	display: flex;
	justify-content: space-between;
	align-items: center;
	position: absolute;
	width: 100%;

	#logo {
		width: 120px;
	}

	#socials {
		display: flex;
		gap: 20px;

		.social-icon {
			width: 30px;
			height: 30px;

			@media screen and (min-width: $Width-Desktop) {
				width: 35px;
				height: 35px;
			}
		}
	}
}