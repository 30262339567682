@import "../Common";

#token-sale-tint {
	top: 0;
	left: 0;
	right: 0;
	bottom: 0;
	position: fixed;
	transition: 0.5s;
	z-index: $Layer-TokenSale;
}

#token-sale-container {
	display: flex;
	justify-content: center;
	align-items: center;
	left: 0;
	right: 0;
	bottom: 0;
	height: 100%;
	transition: 0.5s;
	position: fixed;
	pointer-events: none;
	z-index: $Layer-TokenSale;

	#token-sale {
		background-color: $Accent-Purple2;
		display: flex;
		flex-direction: column;
		align-items: center;
		gap: 20px;
		padding: 40px;
		width: 400px;
		transition: top 0.5s ease 0s;
		pointer-events: auto;

		@media screen and (min-width: $Width-Tablet) {
			padding: 60px;
			width: 500px;
		}
		@media screen and (min-width: $Width-Desktop) {
			padding: 80px;
			width: 700px;
		}

		#token-sale-title {
			color: black;
			font-weight: 700;
			font-size: 22px;

			@media screen and (min-width: $Width-Tablet) { font-size: 24px; }
			@media screen and (min-width: $Width-Desktop) { font-size: 28px;}
		}

		#token-sale-description {
			color: black;
			white-space: pre-wrap;
			text-align: center;
			font-weight: 600;
		}
	}
}

.token-sale-tint-visible {
	background-color: rgba(black, 0.2);
	pointer-events: auto;
}

.token-sale-tint-invisible {
	background-color: transparent;
	pointer-events: none;
}

.token-sale-container-visible { top: 0; }

.token-sale-container-invisible { top: -1000px; }