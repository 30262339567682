@import "src/Assets/Styles/Common";

#unisection {
	background-image: url("../../Images/Home/Unisection.png");
	background-size: cover;
	min-height: 3800px;
	max-height: 4200px;
	overflow: hidden;
	position: relative;

	#unisection-pseudo-image {
		width: 100%;
		min-height: 3800px;
		max-height: 4200px;
		object-fit: contain;
		visibility: hidden;
		position: absolute;
	}
}