@import "src/Assets/Styles/Common";

#merge-heroes-nfts {
	display: grid;
	grid-template-rows: auto;
	grid-template-columns: auto auto;
	gap: 20px;

	@media screen and (min-width: $Width-Tablet) { grid-template-columns: auto auto auto auto auto; }

	.merge-heroes-nft {
		width: 130px;

		@media screen and (min-width: $Width-Tablet) { width: 140px; }
		@media screen and (min-width: $Width-Desktop) { width: 210px; }
	}
}