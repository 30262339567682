@import "src/Assets/Styles/Common";

#register-section {
	background-color: $Accent-Purple2;

	#register {
		display: flex;
		flex-direction: column;
		align-items: center;
		gap: 30px;

		#register-logo {
			width: 150px;
		}

		#register-title {
			color: black;
			font-weight: 700;
			font-size: 22px;

			@media screen and (min-width: $Width-Tablet) { font-size: 24px; }
			@media screen and (min-width: $Width-Desktop) { font-size: 28px; }
		}

		#register-description {
			color: black;
			font-weight: 600;
		}

		#subscribe-options {
			display: flex;
			flex-direction: column;
			gap: 10px;

			@media screen and (min-width: $Width-Tablet) { gap: 12.5px; }
			@media screen and (min-width: $Width-Desktop) { gap: 15px; }

			.category {
				cursor: pointer;
				display: flex;
				align-items: center;
				gap: 10px;

				.category-checkbox {
					width: 20px;
					height: 20px;
					position: relative;

					@media screen and (min-width: $Width-Tablet) {
						width: 22.5px;
						height: 22.5px;
					}

					@media screen and (min-width: $Width-Desktop) {
						width: 25px;
						height: 25px;
					}
				}

				.category-checkbox-box {
					border: 3px solid black;
					width: 100%;
					height: 100%;
					position: absolute;
					transition: 0.25s;
				}

				.category-checkbox-box-unchecked { background-color: transparent; }

				.category-checkbox-box-checked { background-color: black; }

				.category-checkbox-check {
					width: 100%;
					height: 100%;
					position: absolute;
				}

				.category-title {
					color: black;
					font-weight: 500;
				}
			}
		}

		#register-email {
			width: 200px;

			@media screen and (min-width: $Width-Tablet) { width: 250px; }
			@media screen and (min-width: $Width-Desktop) { width: 300px; }
		}
	}
}