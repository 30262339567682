@import "src/Assets/Styles/Common";

#footer-section {
	background-color: black;

	#footer-container {
		display: flex;
		flex-direction: column;
		justify-content: right;
		align-items: center;
		gap: 20px;
		flex: 2;

		@media screen and (min-width: $Width-Tablet) { width: $Width-Tablet; }
		@media screen and (min-width: $Width-Desktop) {
			flex-direction: row;
			width: $Width-Desktop;
		}

		#footer-copyright {
			@media screen and (min-width: $Width-Tablet) { font-size: 15px; }
			@media screen and (min-width: $Width-Desktop) { font-size: 16px; }
		}

		#footer-socials {
			display: flex;
			gap: 10px;

			.footer-social {
				padding: 12.5px 15px;

				&:hover {
					background-color: #aaaaaa;
				}

				.footer-social-icon {
					color: black;
					font-size: 15px;

					@media screen and (min-width: $Width-Tablet) { font-size: 17.5px; }
					@media screen and (min-width: $Width-Desktop) { font-size: 20px; }
				}
			}
		}
	}
}