@import "src/Assets/Styles/Common";

#aquarians-in-niten {
	display: flex;
	flex-direction: column;
	align-items: center;
	gap: 20px;
	padding: 80px 0;

	#aquarian-nft {
		$Image-Size-Mobile: 200px;
		$Image-Size-Tablet: 350px;
		$Image-Size-Desktop: 500px;

		width: $Image-Size-Mobile;
		height: $Image-Size-Mobile;

		@media screen and (min-width: $Width-Tablet) {
			width: $Image-Size-Tablet;
			height: $Image-Size-Tablet;
		}

		@media screen and (min-width: $Width-Desktop) {
			width: $Image-Size-Desktop;
			height: $Image-Size-Desktop;
		}
	}

	.aquarians-section-buttons {
		display: flex;
		flex-direction: column;
		gap: 10px;
		padding: 20px 0;

		@media screen and (min-width: $Width-Desktop) { flex-direction: row; }
	}

	@media screen and (min-width: $Width-Tablet) {
		gap: 30px;
		padding: 120px 0;
	}
	@media screen and (min-width: $Width-Desktop) {
		flex-direction: row;
		justify-content: center;
		gap: 40px;
		padding: 160px 0;
	}
}