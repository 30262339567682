@import "../Common";

#suggested-wallets {
	display: flex;
	flex-direction: column;
	gap: 20px;

	.wallet {
		display: flex;
		align-items: center;
		gap: 15px;

		.wallet-icon {
			width: 35px;
			height: 35px;

			@media screen and (min-width: $Width-Tablet) {
				width: 40px;
				height: 40px;
			}

			@media screen and (min-width: $Width-Desktop) {
				width: 45px;
				height: 45px;
			}
		}
	}
}