@import "src/Assets/Styles/Common";

#navbar-container {
	display: flex;
	align-items: center;
	padding: 20px 40px;
	z-index: $Layer-NavBar;
	width: 100%;
	position: fixed;
	background-image: linear-gradient(0deg, transparent 0%, rgba($Accent-Bg, 0.5) 100%);

	#navbar-logo {
		width: 120px;
	}

	.navbar-links {
		display: flex;
		flex-direction: column;
		gap: 15px;

		@media screen and (min-width: $Width-Desktop) {
			flex-direction: row;
			gap: 40px;
		}

		.navbar-link {
			cursor: pointer;
			font-size: 16px;
			transition: 0.5s;

			&:hover {
				color: #9031ff;
			}
		}
	}

	#navbar-mobile {
		display: flex;
		align-items: center;
		justify-content: space-between;
		width: 100%;

		#navbar-tint {
			top: 0;
			left: 0;
			right: 0;
			bottom: 0;
			position: fixed;
			transition: 0.5s;
		}

		#navbar-menu-container {
			top: 0;
			left: 0;
			right: 0;
			bottom: 0;
			position: fixed;
			pointer-events: none;

			#navbar-menu {
				background-color: $Accent-Bg;
				display: flex;
				flex-direction: column;
				gap: 35px;
				width: 300px;
				height: 100%;
				padding: 20px 30px;
				position: absolute;
				transition: 0.5s;
				pointer-events: auto;

				#navbar-close {
					width: 35px;
					pointer-events: auto;
				}
			}
		}

		.navbar-tint-opened {
			background-color: rgba(black, 0.2);
			pointer-events: auto;
		}

		.navbar-tint-closed {
			background-color: transparent;
			pointer-events: none;
		}

		.navbar-menu-opened { right: 0; }

		.navbar-menu-closed { right: -1000px; }

		#navbar-open {
			width: 35px;
		}

		@media screen and (min-width: $Width-Desktop) { display: none; }
	}

	#navbar-desktop {
		display: none;
		justify-content: space-between;
		align-items: center;
		width: 100%;

		@media screen and (min-width: $Width-Desktop) { display: flex; }
	}
}

.navbar-scrolled {
	background-color: $Accent-Bg;
	box-shadow: 0 15px 25px rgba(#2a4371, 0.15);
	animation-name: navbar-scrolled-anim;
	animation-duration: 0.5s;
}

@keyframes navbar-scrolled-anim {
	0% { top: -100px; }
	100% { top: 0; }
}