@import "src/Assets/Styles/Common";

#parallax {
	overflow: hidden;

	#parallax-center {
		display: flex;
		flex-direction: column;
		align-items: center;
		gap: 20px;
		top: 250px;
		left: 50%;
		transform: translateX(-50%);
		z-index: $Layer-Parallax-Center;
		position: absolute;

		#parallax-logo {
			width: 200px;

			@media screen and (min-width: $Width-Tablet) { width: 250px; }
			@media screen and (min-width: $Width-Desktop) { width: 300px; }
		}

		#parallax-text {
			font-weight: 600;
			font-size: 16px;

			@media screen and (min-width: $Width-Tablet) { font-size: 18px; }
			@media screen and (min-width: $Width-Desktop) { font-size: 20px; }
		}
	}

	#parallax-pseudo-image {
		width: 100%;
		min-height: 800px;
		max-height: 1400px;
		object-fit: cover;
		visibility: hidden;
		position: relative;
	}

	#parallax-static {
		background-image: url("../../Images/Home/Parallax/Static.png");
		background-size: cover;
		background-position: center;
		position: absolute;

		@media screen and (min-width: $Width-Desktop) { visibility: hidden; }
	}

	#parallax-layers {
		width: 100%;
		height: 1400px;
		min-height: 1000px;
		z-index: $Layer-Parallax;
		overflow: hidden;
		visibility: hidden;
		position: absolute;

		#parallax-layer-1 {
			background-image: url("../../Images/Home/Parallax/Layer1.png");
		}

		#parallax-layer-2 {
			background-image: url("../../Images/Home/Parallax/Layer2.png");
		}

		#parallax-layer-3 {
			background-image: url("../../Images/Home/Parallax/Layer3.png");
		}

		#parallax-layer-4 {
			background-image: url("../../Images/Home/Parallax/Layer4.png");
		}

		.parallax-layer {
			background-size: cover;
			background-position: center;
			display: block;
			width: 102%;
			height: 102%;
			position: absolute;

			img {
				width: 100%;
				min-height: 1000px;
				max-height: 1400px;
				object-fit: cover;
				position: absolute;
			}
		}

		@media screen and (min-width: $Width-Desktop) { visibility: visible; }
	}
}