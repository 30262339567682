@import "src/Assets/Styles/Common";

#watch-trailer {
	display: flex;
	flex-direction: column-reverse;
	align-items: center;
	gap: 40px;

	#watch-trailer-media,
	#watch-trailer-media-video,
	#watch-trailer-media-video-border,
	#watch-trailer-media-video-pseudo-image {
		$Video-AspectRatio: 16px / 9px;
		$Video-Width-Mobile: 350px;

		width: $Video-Width-Mobile;
		height: $Video-Width-Mobile / $Video-AspectRatio;

		@media screen and (min-width: $Width-Tablet) {
			$Video-Width-Tablet: 550px;

			width: $Video-Width-Tablet;
			height: $Video-Width-Tablet / $Video-AspectRatio;
		}

		@media screen and (min-width: $Width-Desktop) {
			$Video-Width-Desktop: 700px;

			width: $Video-Width-Desktop;
			height: $Video-Width-Desktop / $Video-AspectRatio;
		}
	}

	#watch-trailer-media-video,
	#watch-trailer-media-video-border {
		position: absolute;
	}

	#watch-trailer-media-video-border {
		pointer-events: none;
	}

	#watch-trailer-media-video-pseudo-image {
		object-fit: cover;
		visibility: hidden;
	}

	#watch-trailer-text-description {
		width: 300px;

		@media screen and (min-width: $Width-Desktop) { width: 400px; }
	}

	@media screen and (min-width: $Width-Desktop) {
		flex-direction: row;
		gap: 80px;
	}
}