@import "src/Assets/Styles/Common";

$Chunk1-Color: #8500de;
$Chunk2-Color: #c70066;
$Chunk3-Color: #c800d9;
$Chunk4-Color: #5d00c5;
$Chunk5-Color: #003a96;
$Chunk6-Color: #0097af;
$Chunk7-Color: #008d49;

#token-dist {
	display: flex;
	flex-direction: column;
	align-items: center;
	gap: 60px;

	@media screen and (min-width: $Width-Desktop) { flex-direction: row; }

	#token-dist-chart {
		width: 350px;
		height: 350px;
		position: relative;

		@media screen and (min-width: $Width-Tablet) {
			width: 400px;
			height: 400px;
		}

		@media screen and (min-width: $Width-Desktop) {
			width: 550px;
			height: 550px;
		}

		.token-dist-chart-circle {
			border-radius: 50%;
			display: flex;
			justify-content: center;
			align-items: center;
			top: 50%;
			left: 50%;
			position: absolute;
			box-shadow: 0 0 100px 1px;

			.token-dist-chart-circle-text {
				font-weight: 700;
			}
		}

		#token-dist-chart-circle7 {
			color: $Chunk7-Color;
			background-color: rgba($Chunk7-Color, 0.8);
			width: 49px;
			height: 49px;
			transform: translate(190%, 24%);

			.token-dist-chart-circle-text { font-size: 11px; }

			@media screen and (min-width: $Width-Tablet) {
				width: 56px;
				height: 56px;

				.token-dist-chart-circle-text { font-size: 14px; }
			}

			@media screen and (min-width: $Width-Desktop) {
				width: 70px;
				height: 70px;

				.token-dist-chart-circle-text { font-size: 16px; }
			}
		}

		#token-dist-chart-circle6 {
			color: $Chunk6-Color;
			background-color: rgba($Chunk6-Color, 0.8);
			width: 49px;
			height: 49px;
			transform: translate(108%, 65%);

			.token-dist-chart-circle-text { font-size: 11px; }

			@media screen and (min-width: $Width-Tablet) {
				width: 56px;
				height: 56px;

				.token-dist-chart-circle-text { font-size: 14px; }
			}

			@media screen and (min-width: $Width-Desktop) {
				width: 70px;
				height: 70px;

				.token-dist-chart-circle-text { font-size: 16px; }
			}
		}

		#token-dist-chart-circle5 {
			color: $Chunk5-Color;
			background-color: rgba($Chunk5-Color, 0.8);
			width: 70px;
			height: 70px;
			transform: translate(-136%, -168%);

			.token-dist-chart-circle-text { font-size: 14px; }

			@media screen and (min-width: $Width-Tablet) {
				width: 85px;
				height: 85px;

				.token-dist-chart-circle-text { font-size: 17px; }
			}

			@media screen and (min-width: $Width-Desktop) {
				width: 100px;
				height: 100px;

				.token-dist-chart-circle-text { font-size: 20px; }
			}
		}

		#token-dist-chart-circle4 {
			color: $Chunk4-Color;
			background-color: rgba($Chunk4-Color, 0.8);
			width: 77px;
			height: 77px;
			transform: translate(-83%, 94%);

			.token-dist-chart-circle-text { font-size: 16px; }

			@media screen and (min-width: $Width-Tablet) {
				width: 90px;
				height: 90px;

				.token-dist-chart-circle-text { font-size: 20px; }
			}

			@media screen and (min-width: $Width-Desktop) {
				width: 110px;
				height: 110px;

				.token-dist-chart-circle-text { font-size: 24px; }
			}
		}

		#token-dist-chart-circle3 {
			color: $Chunk3-Color;
			background-color: rgba($Chunk3-Color, 0.8);
			width: 112px;
			height: 112px;
			transform: translate(-126%, -2%);

			.token-dist-chart-circle-text { font-size: 19px; }

			@media screen and (min-width: $Width-Tablet) {
				width: 130px;
				height: 130px;

				.token-dist-chart-circle-text { font-size: 21px; }
			}

			@media screen and (min-width: $Width-Desktop) {
				width: 160px;
				height: 160px;

				.token-dist-chart-circle-text { font-size: 28px; }
			}
		}

		#token-dist-chart-circle2 {
			color: $Chunk2-Color;
			background-color: rgba($Chunk2-Color, 0.8);
			width: 112px;
			height: 112px;
			transform: translate(34%, -133%);

			.token-dist-chart-circle-text { font-size: 19px; }

			@media screen and (min-width: $Width-Tablet) {
				width: 130px;
				height: 130px;

				.token-dist-chart-circle-text { font-size: 21px; }
			}

			@media screen and (min-width: $Width-Desktop) {
				width: 160px;
				height: 160px;

				.token-dist-chart-circle-text { font-size: 28px; }
			}
		}

		#token-dist-chart-circle1 {
			color: $Chunk1-Color;
			background-color: rgba($Chunk1-Color, 0.8);
			width: 154px;
			height: 154px;
			transform: translate(-41%, -58%);

			.token-dist-chart-circle-text { font-size: 26px; }

			@media screen and (min-width: $Width-Tablet) {
				width: 185px;
				height: 185px;

				.token-dist-chart-circle-text { font-size: 32px; }
			}

			@media screen and (min-width: $Width-Desktop) {
				width: 220px;
				height: 220px;

				.token-dist-chart-circle-text { font-size: 38px; }
			}
		}
	}

	#token-dist-legend {
		display: flex;
		flex-direction: column;
		justify-content: center;
		gap: 15px;

		.token-dist-chart-legend-row {
			display: flex;
			align-items: center;
			gap: 15px;

			.token-dist-chart-legend-row-icon {
				border-radius: 50%;
				width: 10px;
				height: 10px;
			}

			#token-dist-legend-row-icon-1 { background-color: $Chunk1-Color; }

			#token-dist-legend-row-icon-2 { background-color: $Chunk2-Color; }

			#token-dist-legend-row-icon-3 { background-color: $Chunk3-Color; }

			#token-dist-legend-row-icon-4 { background-color: $Chunk4-Color; }

			#token-dist-legend-row-icon-5 { background-color: $Chunk5-Color; }

			#token-dist-legend-row-icon-6 { background-color: $Chunk6-Color; }

			#token-dist-legend-row-icon-7 { background-color: $Chunk7-Color; }

			.token-dist-chart-legend-row-data {
				display: flex;
				justify-content: space-between;
				width: 250px;

				@media screen and (min-width: $Width-Tablet) { width: 350px; }
				@media screen and (min-width: $Width-Desktop) { width: 400px; }

				.token-dist-chart-legend-row-text {
					font-weight: 300;
				}

				#token-dist-chart-legend-row-percent {
					float: right;
				}
			}
		}
	}
}