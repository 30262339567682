@import "src/Assets/Styles/Common";

#galaxy-background {
	background-image: url("../../Images/Aquarians/GalaxyBackground.png");
	background-size: cover;
	background-position: center;
	width: 100%;
	height: 100%;
	z-index: $Layer-Background;
	position: fixed;
}