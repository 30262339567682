@import "Common";

#content {
	background-image: url("../Images/Mint/Background.png"), linear-gradient(0deg, #0f0515, #1d0a29);
	background-size: cover;
	background-position: center;
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	width: 100vw;
	height: 100vh;

	.group {
		background-color: rgba(white, 0.1);
		border-radius: 30px;
		display: flex;
		flex-direction: column;
		justify-content: center;
		align-items: center;
		padding: 20px;

		@media screen and (min-width: $Width-Tablet) { padding: 30px; }
		@media screen and (min-width: $Width-Desktop) { padding: 40px; }
	}

	#main {
		gap: 20px;

		@media screen and (min-width: $Width-Tablet) { gap: 25px; }
		@media screen and (min-width: $Width-Desktop) { gap: 30px; }

		#nft {
			display: flex;
			align-items: center;
			width: 300px;
			height: 300px;

			@media screen and (min-width: $Width-Tablet) {
				width: 350px;
				height: 350px;
			}

			@media screen and (min-width: $Width-Desktop) {
				width: 400px;
				height: 400px;
			}
		}

		.dialog-title {
			font-weight: 500;
		}

		.dialog-note {
			color: rgba(white, 0.8);
			font-weight: 500;
			text-align: center;
			width: 300px;

			.dialog-note-link {
				text-decoration: underline;
			}

			@media screen and (min-width: $Width-Tablet) { width: 400px; }
			@media screen and (min-width: $Width-Desktop) { width: 500px; }
		}

		.button-icon {
			display: flex;
			align-items: center;
			gap: 10px;
		}

		.button-thin {
			padding: 10px 17.5px;
		}
	}
}