@import url("https://fonts.googleapis.com/css2?family=Sora:wght@300;400;500;600;700&display=swap");

$Accent-Fg: white;
$Accent-Bg: #1b0a26;
$Accent-Purple: #ba04fe;
$Accent-Purple2: #9031ff;

$Layer-Preloader: 999;
$Layer-TokenSale: 4;
$Layer-NavBar: 3;
$Layer-Parallax-Center: 2;
$Layer-Parallax: 1;
$Layer-Background: -1;

$Width-Tablet: 800px;
$Width-Desktop: 1200px;

* {
	margin: 0;
	padding: 0;
	box-sizing: border-box;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
}

html, body {
	width: 100%;
	height: 100%;
}

ul {
	list-style: none;
}

p, a, input {
	color: white;
	font-family: "Sora", sans-serif;
	font-weight: 400;
	font-size: 14px;

	@media screen and (min-width: $Width-Tablet) {
		font-size: 16px;
	}
	@media screen and (min-width: $Width-Desktop) {
		font-size: 18px;
	}
}

a {
	text-decoration: none;
}

input {
	background-color: transparent;
	border: none;
	text-align: center;

	&:focus {
		outline: none;
	}
}

.button {
	color: $Accent-Bg;
	background-color: $Accent-Fg;
	font-family: "Sora", sans-serif;
	font-weight: 500;
	font-size: 14px;
	text-align: center;
	border: none;
	border-radius: 45px;
	padding: 10px 55px;
	cursor: pointer;
	transition: 0.5s;

	@media screen and (min-width: $Width-Tablet) {
		font-size: 16px;
	}
	@media screen and (min-width: $Width-Desktop) {
		font-size: 18px;
	}

	&:hover {
		color: $Accent-Fg;
		background-color: $Accent-Bg;
	}
}

.button-purple {
	color: $Accent-Fg;
	background-color: $Accent-Purple;

	&:hover {
		color: $Accent-Bg;
		background-color: $Accent-Fg;
	}
}

.button-black {
	color: white;
	background-color: black;

	&:hover {
		color: black;
		background-color: white;
	}
}

.button-large {
	padding: 15px 70px;
}