@import "src/Assets/Styles/Common";

#metaverse-small {
	width: 380px;
	display: block;

	@media screen and (min-width: $Width-Tablet) { display: none; }
	@media screen and (min-width: $Width-Desktop) { display: none; }
}

#metaverse-large {
	display: none;

	@media screen and (min-width: $Width-Tablet) {
		width: 700px;
		display: block;
	}

	@media screen and (min-width: $Width-Desktop) {
		width: 1100px;
		display: block;
	}
}