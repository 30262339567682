@import "src/Assets/Styles/Common";

#join-in-section {
	background-image: url("../../Images/Home/JoinIn/Background.jpg");
	background-size: cover;
	background-position: center;

	#join-in {
		display: flex;
		flex-direction: column;
		align-items: center;
		gap: 40px;

		@media screen and (min-width: $Width-Tablet) { flex-direction: row; }

		.join-in-activity {
			display: flex;
			flex-direction: column;
			align-items: center;
			gap: 20px;

			.join-in-activity-icon {
				width: 200px;

				@media screen and (min-width: $Width-Tablet) { width: 225px; }
				@media screen and (min-width: $Width-Desktop) { width: 350px; }
			}
		}
	}
}