@import "src/Assets/Styles/Common";

.section {
	display: flex;
	flex-direction: column;
	align-items: center;
	padding: 20px 0;

	@media screen and (min-width: $Width-Desktop) { padding: 30px 0; }
	@media screen and (min-width: $Width-Desktop) { padding: 40px 0; }

	.section-text {
		display: flex;
		flex-direction: column;
		align-items: center;

		.section-title {
			font-weight: 600;
			font-size: 22px;
			padding: 20px 0;

			@media screen and (min-width: $Width-Tablet) { font-size: 26px; }
			@media screen and (min-width: $Width-Desktop) { font-size: 30px; }
		}

		.section-description {
			color: rgba(white, 0.8);
			font-weight: 500;
			text-align: center;
			width: 350px;

			@media screen and (min-width: $Width-Tablet) { width: 600px; }
			@media screen and (min-width: $Width-Desktop) { width: 1200px; }
		}
	}

	.section-content {
		padding: 20px 0;
	}
}