@import "../Common";

#popup {
	gap: 30px;
	display: flex;
	flex-direction: column;
	align-items: center;

	#popup-icon {
		width: 60px;

		@media screen and (min-width: $Width-Tablet) { width: 80px; }
		@media screen and (min-width: $Width-Desktop) { width: 100px; }
	}

	#popup-message {
		font-weight: 600;
		white-space: pre-wrap;
		text-align: center;
		width: 100%;
	}
}