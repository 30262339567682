@import "src/Assets/Styles/Common";

#background {
	background-color: #220c30;
	background-size: contain;
	width: 100%;
	height: 100%;
	z-index: $Layer-Background;
	position: fixed;
}

#background-flare-1 {
	background-image: radial-gradient(at -20% 120%, #4c2345 0%, transparent 43%);
	width: 400px;
	height: 300px;
	bottom: 0;
	left: 0;
	position: fixed;

	@media screen and (min-width: $Width-Tablet) {
		width: 600px;
		height: 450px;
	}

	@media screen and (min-width: $Width-Desktop) {
		width: 800px;
		height: 600px;
	}
}

#background-flare-2 {
	background-image: radial-gradient(at 120% 70%, #25144b 0%, transparent 57%);
	width: 400px;
	height: 800px;
	bottom: 0;
	right: 0;
	position: fixed;

	@media screen and (min-width: $Width-Tablet) {
		width: 650px;
		height: 1000px;
	}

	@media screen and (min-width: $Width-Desktop) {
		width: 800px;
		height: 1200px;
	}
}